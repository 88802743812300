import * as React from "react"
import { Helmet } from "react-helmet";

import ogImage from "./../images/og-image.jpg";

import Navbar from './../sections/navbar.js';
import Footer from './../sections/footer.js';

class LicensePage extends React.Component {

    render() {
        return (
            <div id="overview">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bergify - License</title>
                    <link rel="canonical" href="https://www.bergify.com/license/" />
                    <meta name="description" content="Bergify is a Wordpress block theme suitable for businesses and offering Full-Site Editing." />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@bergify_" />
                    <meta name="twitter:title" content="Bergify License" />
                    <meta name="twitter:description" content="Premium Wordpress theme for your business" />
                    <meta name="twitter:image" content={'https://www.bergify.com' + ogImage} />
                    <meta name="twitter:creator" content="@bergify_" />

                    <meta property="og:url" content="https://www.bergify.com/" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Bergify License" />
                    <meta property="og:description" content="Premium Wordpress theme for your business" />
                    <meta property="og:image" content={'https://www.bergify.com' + ogImage} />
                </Helmet>
                <Navbar></Navbar>
                <main>
                    <section className="bg-gray-50 py-16 lg:py-24" id="pricing">
                        <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                            <div className="xl:mx-64 2xl:mx-80">
                                <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-6">EULA (End User License Agreement)</h1>

                                <p className="text-gray-600 text-lg lg:text-lg mb-10">Read about the licensing terms for Bergify. Have a question? Feel free to <a href="mailto:info@bergify.com" className="text-blue-600 hover:underline">send email</a> and we'll help you out.</p>
                                <hr className="my-12" />
                                <h2 className="text-gray-900 text-lg mb-4 font-medium">Standard License</h2>
                                <p className="text-gray-600 mb-4">HorMart grants you an on-going, non-exclusive license to use the download files.</p>
                                <p className="text-gray-600 mb-4">The license grants permission to <strong>one individual</strong> (the Licensee) to access and use the download files.</p>
                                <p className="text-gray-600 mb-4">You <strong>can</strong>:</p>
                                <ul className="mb-8 list-disc pl-4">
                                    <li className="text-gray-600 mb-2">Use the download files to create unlimited End Products.</li>
                                    <li className="text-gray-600 mb-2">Modify the download files to create derivative projects. Those projects are subject to this license.</li>
                                    <li className="text-gray-600 mb-2">Use the download files to create unlimited End Products for unlimited Clients.</li>
                                    <li className="text-gray-600 mb-2">Use the download files to create End Products where the End Product is sold to End Users.</li>
                                    <li className="text-gray-600 mb-2">Use the download files to create End Products that are open source and freely available to End Users.</li>
                                </ul>
                                <p className="text-gray-600 mb-4">You <strong>cannot</strong>:</p>
                                <ul>
                                    <li className="text-gray-600 mb-2">Use the download files to create End Products that are designed to allow an End User to build their own End Products using the download files or derivatives of the download files.</li>
                                    <li className="text-gray-600 mb-2">Re-distribute the download files or derivatives of the download files separately from an End Product, neither in code or as design assets.</li>
                                    <li className="text-gray-600 mb-2">Share your access to the download files with any other individuals</li>
                                </ul>
                                <h3 className="text-gray-900 text-lg my-8 font-medium">Example usage</h3>
                                <p className="text-gray-600 mb-4">Examples of usage <strong>allowed</strong> by the license:</p>
                                <ul className="mb-8 list-disc pl-4">
                                    <li className="text-gray-600 mb-2">Creating a personal website by yourself</li>
                                    <li className="text-gray-600 mb-2">Creating a website or web application for a client that will be owned by that client</li>
                                    <li className="text-gray-600 mb-2">Creating a commercial SaaS application (like an invoicing app for example) where end users have to pay a fee to use the application</li>
                                    <li className="text-gray-600 mb-2">Creating a commercial self-hosted web application that is sold to end users for a one-time fee</li>
                                    <li className="text-gray-600 mb-2">Creating a web application where the primary purpose is clearly not to simply re-distribute the download files (like a conference organization app that uses the download files for its UI for example) that is free and open source, where the source code is publicly available</li>
                                </ul>
                                <p className="text-gray-600 mb-4">Examples of usage <strong>not allowed</strong> by the license:</p>
                                <ul className="mb-8 list-disc pl-4">
                                    <li className="text-gray-600 mb-2">Creating a repository of your favorite Bergify elements (or derivatives based on Bergify elements) and publishing it publicly</li>
                                    <li className="text-gray-600 mb-2">Creating a React or Vue version of Bergify and making it available either for sale or for free</li>
                                    <li className="text-gray-600 mb-2">Create a Figma or Sketch UI kit based on the Bergify component designs</li>
                                    <li className="text-gray-600 mb-2">Creating a "website builder" project where end users can build their own websites using download files included with or derived from Bergify</li>
                                    <li className="text-gray-600 mb-2">Creating a theme, template, or project starter kit using the download files and making it available either for sale or for free</li>
                                    <li className="text-gray-600 mb-2">Creating an admin panel tool that is made available either for sale or for free</li>
                                </ul>
                                <p className="text-gray-600 mb-4">In simple terms, use Bergify for anything you like except for projects that compete with Bergify and would have a negative impact on our product sales</p>
                                <h3 className="text-gray-900 text-lg my-8 font-medium">Standard License Definitions</h3>
                                <p className="text-gray-600 mb-4">Licensee is the individual who has purchased a Standard License.</p>
                                <p className="text-gray-600 mb-4">Download files are the HTML source code snippets and Figma assets made available to the Licensee after purchasing a Bergify license.</p>
                                <p className="text-gray-600 mb-4">End Product is any artifact produced that incorporates the download files or derivatives of the download files.</p>
                                <p className="text-gray-600 mb-4">End User is a user of an End Product.</p>
                                <p className="text-gray-600 mb-4">Client is an individual or entity receiving custom professional services directly from the Licensee, produced specifically for that individual or entity. Customers of software-as-a-service products are not considered clients for the purpose of this document.</p>
                                <h2 className="text-gray-900 text-lg mb-4 font-medium">Team License</h2>
                                <p className="text-gray-600 mb-4"><em>Differences from the Standard License have been highlighted.</em></p>
                                <p className="text-gray-600 mb-4">HorMart grants you an on-going, non-exclusive license to use the download files.</p>
                                <p className="text-gray-600 mb-4"><mark className="bg-blue-200">The license grants permission to <strong>all Employees and Contractors of the Licensee</strong> to access and use the download files.</mark></p>
                                <p className="text-gray-600 mb-4">You <strong>can</strong>:</p>
                                <ul className="mb-8 list-disc pl-4">
                                    <li className="text-gray-600 mb-2">Use the download files to create unlimited End Products.</li>
                                    <li className="text-gray-600 mb-2">Modify the download files to create derivative download files. Those download files are subject to this license.</li>
                                    <li className="text-gray-600 mb-2">Use the download files to create unlimited End Products for unlimited Clients.</li>
                                    <li className="text-gray-600 mb-2">Use the download files to create End Products where the End Product is sold to End Users.</li>
                                    <li className="text-gray-600 mb-2">Use the download files to create End Products that are open source and freely available to End Users.</li>
                                </ul>
                                <p className="text-gray-600 mb-4">You <strong>cannot</strong>:</p>
                                <ul className="mb-8 list-disc pl-4">
                                    <li className="text-gray-600 mb-2">Use the download files to create End Products that are designed to allow an End User to build their own End Products using the download files or derivatives of the download files.</li>
                                    <li className="text-gray-600 mb-2">Re-distribute the download files or derivatives of the download files separately from an End Product.</li>
                                    <li className="text-gray-600 mb-2">
                                        <mark className="bg-blue-200">Use the download files to create End Products that are the property of any individual or entity other than the Licensee or Clients of the Licensee.</mark>
                                    </li>
                                </ul>
                                <h3 className="text-gray-900 text-lg my-8 font-medium">Example usage</h3>
                                <p className="text-gray-600 mb-4">Examples of usage <strong>allowed</strong> by the license:</p>
                                <ul className="mb-8 list-disc pl-4">
                                    <li className="text-gray-600 mb-2">
                                        <mark className="bg-blue-200">Creating a website for your company</mark>
                                    </li>
                                    <li className="text-gray-600 mb-2">Creating a website or web application for a client that will be owned by that client</li>
                                    <li className="text-gray-600 mb-2">Creating a commercial SaaS application (like an invoicing app for example) where end users have to pay a fee to use the application</li>
                                    <li className="text-gray-600 mb-2">Creating a commercial self-hosted web application that is sold to end users for a one-time fee</li>
                                    <li className="text-gray-600 mb-2">Creating a web application where the primary purpose is clearly not to simply re-distribute the download files (like a conference organization app that uses the download files for its UI for example) that is free and open source, where the source code is publicly available</li>
                                </ul>
                                <p className="text-gray-600 mb-4">Examples of use <strong>not allowed</strong> by the license:</p>
                                <ul className="mb-8 list-disc pl-4">
                                    <li className="text-gray-600 mb-2">Creating a repository of your favorite Bergify elements (or derivatives based on Bergify elements) and publishing it publicly</li>
                                    <li className="text-gray-600 mb-2">Creating a React or Vue version of Bergify and making it available either for sale or for free</li>
                                    <li className="text-gray-600 mb-2">Creating a "website builder" project where end users can build their own websites using download files included with or derived from Bergify</li>
                                    <li className="text-gray-600 mb-2">Creating a theme or template using the download files and making it available either for sale for for free</li>
                                    <li className="text-gray-600 mb-2">Creating an admin panel tool that is made available either for sale or for free</li>
                                    <li className="text-gray-600 mb-2">
                                        <mark className="bg-blue-200">Creating any End Product that is not the sole property of either your company or a client of your company. For example your employees/contractors can't use your company Bergify license to build their own websites or side projects.</mark>
                                    </li>
                                </ul>
                                <h3 className="text-gray-900 text-lg my-8 font-medium">Team License Definitions</h3>
                                <p className="text-gray-600 mb-4">Licensee is the business entity who has purchased a Team License.</p>
                                <p className="text-gray-600 mb-4">Download files are the HTML source code snippets and Figma assets made available to the Licensee after purchasing a Bergify license.</p>
                                <p className="text-gray-600 mb-4">End Product is any artifact produced that incorporates the download files.</p>
                                <p className="text-gray-600 mb-4">End User is a user of an End Product.</p>
                                <p className="text-gray-600 mb-4">Employee is a full-time or part-time employee of the Licensee.</p>
                                <p className="text-gray-600 mb-4">Contractor is an individual or business entity contracted to perform services for the Licensee.</p>
                                <p className="text-gray-600 mb-4">Client is an individual or entity receiving custom professional services directly from the Licensee, produced specifically for that individual or entity. Customers of software-as-a-service products are not considered clients for the purpose of this document.</p>
                                <h2 className="text-gray-900 text-lg my-8 font-medium">Liability</h2>
                                <p className="text-gray-600 mb-4">HorMart’s liability to you for costs, damages, or other losses arising from your use of the download files — including third-party claims against you — is limited to a refund of your license fee. HorMart. may not be held liable for any consequential damages related to your use of the download files.</p>
                                <h2 className="text-gray-900 text-lg my-8 font-medium">Questions?</h2>
                                <p className="text-gray-600 mb-4">Unsure which license you need, or unsure if your use case is covered by our licenses?</p>
                                <p className="text-gray-600 mb-4"><a href="mailto:info@bergify.com" target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">Send email</a> if you have any questions.</p>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </main>
            </div>
        )
    }
}
export default LicensePage